.RegisterForm-inputContainer{
  margin-top: 10px;
  margin-bottom: 0px;
  width: 100%;
  min-width: 300px;
}

.RegisterForm-submit{
  margin-bottom: 40px;
  margin-left: 0;
  width: 100%;
  padding: 5px 0;
}

.animate-show-hide.ng-hide {
  opacity: 0;
}

.animate-show-hide.ng-hide-add,
.animate-show-hide.ng-hide-remove {
  transition: all linear 0.5s;
}

.check-element {
  border: 1px solid black;
  opacity: 1;
  padding: 10px;
}
