md-toolbar {
	background: #2a5cdc !important;
}
.sidebar-icon {
	/* theme uyumluluk için kaldırılmalı*/
	color:rgb(114 114 114) !important;
}

.md-show {
	position: absolute;
}

md-menu-content {
	/*background: #5270bd;*/
}
