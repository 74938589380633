.action-row {
  display: flex;
  align-items: end;
}

.action-button {
  display: flex;
  justify-content: end;
}

.action-status {
  margin-left: 10px;

  .action-status-info {
    display: flex;
    align-items: center;
  }
}

a {
  cursor: pointer;
}

@media(max-width: 728px) {
  .action-row {
    display: block;
    align-items: normal;
  }
  .action-file-type {
    margin-bottom: 10px;
  }
}

.report-status-listening {
  color: #ababab;
}

.report-status-waiting {
  color: #c8b649;
}

.report-status-inProgress {
  color: #caffba;
}