#import-excel-step-list {
  height: 50px;
  align-items: center;
  justify-content: start;

  .import-excel-step {
    margin: 5px 0;
    cursor: pointer !important;
    height: 100%;
    padding-left: 10px;

    &.active-step {
      background: #eee;
      border-radius: 3px;
    }

    .import-excel-step-action {
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.54);

      &:hover {
        text-decoration: none !important;
        cursor: pointer !important;

        label {
          cursor: pointer !important;
        }
      }

      label {
        margin-left: 5px;
        margin-top: 8px;
      }
    }
  }
}

#step-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#import-excel-step-content {
  margin: 10px 0;
  display: flex;
  align-items: center;

  .import-excel-template-field-button {
    margin-bottom: 0;
    background: #f3f3f330;
    width: 100%;
    margin-left: 0;
    padding: 0;
    margin-right: 0;
  }

  .import-excel-step-content-file-input-group {
    align-items: center;
    min-height: 50px;
    background-color: #b9b5b51c;
    border-radius: 3px;
    padding: 0 10px;

    #filex {
      display: none;
    }

    span {
      padding: 0 10px;
    }
  }

  .import-excel-step-content-file-service-group {
    justify-content: space-between;
    padding: 0 10px;
    margin: 10px 0;
    align-items: center;

    #import-excel-step-content-file-service-group-header-checkbox {
      display: flex;
      justify-content: end;

      md-checkbox {
        padding-top: 15px;
      }
    }
  }
}

.import-excel-template {
  background: #f3f3f330;
  /*padding: 20px 15px;*/

  .import-excel-template-field {
    /*min-height: 60px;*/
    align-items: center;
  }
}

.import-excel-template-action {
  display: flex;
  justify-content: end;
  margin: 20px 0;
}

.import-excel-detail {
  .import-excel-detail-count {
    b {
      color: red;
    }
  }
}

@media (max-width: 599px) {
  #import-excel-step-content {
    .import-excel-step-content-file-service-group {
      align-items: normal;

      #import-excel-step-content-file-service-group-header-checkbox {
        justify-content: start;
      }
    }
  }
}

.upload-lists {
  .upload-lists-header, .upload-lists-content-item {
    align-items: center;
    border: 1px  solid #ccc;
  }

  .word-wrap {
    word-wrap: break-word;
  }

  .upload-lists-header-item-field, .upload-lists-content-item-field {
    margin-left: 5px;
    border-right: 0.5px solid #ccc;
    height: 100%;
  }

  .upload-lists-content {
    min-height: 30px;


    .upload-lists-content-item-field {
      min-height: 50px;
      display: flex;
      align-items: center;
      word-break: break-word;
      padding: 5px;

      .md-button {
        margin: 0;
        padding: 0;
      }
    }

    .upload-lists-content-ready {
      background-color: #f8e78e;
    }

    .upload-lists-content-pending {
      background-color: #ff9898;
    }

    .upload-lists-content-inProgress {
      background-color: #76ffc4;
    }

    .upload-lists-content-approval {
      background-color: #37c400;
    }
  }
}

.upload-lists-filter {
  margin: 0 5px 20px 5px;
  font-size: 15px;
}

.upload-lists-cart-title {
  margin: 10px;
  h2{
    margin-right: 10px;
  }
  .upload-lists-cart-title-refresh-button{
    background-color: #eeeeee;
    border-radius: 30px !important;
  }
}

.upload-data-rows-header-item-field-in-used, .upload-data-rows-content-item-field-in-used {
  background-color: #b3fab5;
  font-weight: bold;
}

.upload-list-rows-list-ready {
  background-color: #f8e78e !important;
}

.upload-list-rows-list-error {
  background-color: #ff9898 !important;
}

.upload-list-rows-list-delete {
  background-color: #e82c2c !important;
}

.upload-list-rows-list-update {
  background-color: #76ffc4 !important;
}

.upload-list-rows-list-create {
  background-color: #37c400 !important;
}

.upload-data-rows {
  .upload-data-rows-action {
    margin: 25px 0;
    .upload-data-rows-action-item {
      margin-right: 10px;

      .upload-data-rows-action-accept-button{
        background-color: green;
        color: white;
      }

      .upload-data-rows-action-remove-button{
        background-color: red;
        color: white;
      }
    }
  }
}
