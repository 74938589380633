.cell-alignment {
    padding-top: 28px !important;
}
/* theme uyumluluk için kaldırılmali*/
.gray-table {
    margin-top:10px;
    background-color: #efefef;
    border: 1px solid #ccc;
}

.gray-table tbody > tr > td {
    padding: 2px 8px 2px 8px !important;
    border-top: 0px;
}

.gray-table tbody > tr:nth-child(1) td {
  //  border-top: 1px solid #ddd;
}

.gray-table tbody > tr > td .mini-btn {
    margin: 0px !important;
}
/* theme uyumluluk için kaldırılmalı */
table {
    border-collapse: separate;
    border:1px solid #ccc;
    text-indent: initial;
    border-spacing: 2px;
}

table.lh-1 > tbody > tr > td {
    line-height: 1 !important;
    padding: 0px !important;
    font-size: 11px !important;
    border: 1px solid #ccc;
    display: table-cell;
}

table.lh-1 > tbody > tr > td > a.mini-btn,
table.lh-1 > tbody > tr > td > .md-menu > .mini-btn {
    border-radius: 3px;
    box-sizing: border-box;
    color: currentColor;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    outline: none;
    border: 0;
    display: inline-block;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 2px !important;
    margin: 1px 2px 1px 0px !important;
    line-height: 6px !important;
    min-height: 6px !important;
    background: transparent;
    white-space: nowrap;
    min-width: 10px !important;
    text-align: center;
    text-transform: uppercase;
    font-weight: 200 !important;
    font-size: 13px !important;
    top: 0px !important;
}

table.lh-1 > tbody > tr > td > md-icon.material-icons {
    font-size: 19px !important;
}

table.lh-1-pd-5 > tbody > tr > td {
    padding: 5px !important;
}

table#order-status > tbody > tr > td md-switch {
    margin: 0px !important;
}

table.lh-1 > tbody > tr > td:first-child {
    padding-left: 8px !important;
}
.well-pd10 {
    padding: 10px !important;
}

md-input-container {
    //padding: 0px !important;
    //margin: 20px 10px 0px 0px !important;
}

md-input-container.md-has-icon {
    //padding-left: 36px !important;
}

.md-toolbar-tools h1,
.md-toolbar-tools h2,
.md-toolbar-tools h3 {
    color: #fff !important;
}

.md-datepicker-input-mask {
    height: 0;
}

md-input-container {
    /*margin:10px !important;*/
}
md-input-container .md-errors-spacer {
    height: 0;
}

.md-datepicker-input-container .md-errors-spacer{
    min-height: 0;
}
.md-tooltip {
    height: auto !important;
}
.virtual-stock-quantity {
    background: #9e9e9e !important;
}

.critical-stock-success {
    background: #00a65a !important;
}
.critical-stock-warning {
    background: #979656 !important;
}
.critical-stock-danger {
    background: #b6012f !important;
}

.order-list-form{
    md-checkbox .md-label{
        margin-left: 30px;
        margin-right: 20px;
    }
}
.unit-price-alert-danger{
    background-color: #ff9c9c;
}

.new-action-list-normal{
    background-color: #9e9e9e;
}
.new-action-list-high{
    background-color: #35ad1a;
}
.new-action-list-very-high{
    background-color: #f16d04;
    color: white;
}
.new-action-list-urgent{
    background-color: #ff0000;
    color:white;
}
.new-action-list-label{
    height: 25px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
}

.color-new-action-list-normal{
    color: #cdcbcb;
}
.color-new-action-list-high{
    color: #d5b3b3;
}
.color-new-action-list-very-high{
    color: #c25e5e;
}
.color-new-action-list-urgent{
    color: #6646a8;
}

.alert-effect {
    transition: width 2.5s, height 2.5s;
}

.alert-effect:hover {
    border-radius: 100%;
    transition: width 4s , height 4s;
    background-color: #ff96ad;
    box-shadow: 0 0 5px #ff96ad, 0 0 25px #ff96ad, 0 0 50px #ff96ad, 0 0 200px #ff96ad;
}

.fw700 {
    font-weight: 700 !important;
}

.list-action-detail{
    position: relative;
    margin-bottom: 10px;
    .percent{
        position: absolute;
        height: 100%;
     /*   z-index: -1;*/
        background-color: #8bc34a45;
    }
    .label-info{
        color: #261b18;
        background-color: #9e9e9e59;
        align-items: center;
        justify-content: center;
        display: flex;
        line-height: 2;
        min-width: 110px;
        z-index: 0;
        .label-info-data{
            font-weight: 700;
            font-size: 25px;
            color: #f34336;
        }
    }
}

.scrollable-max-height-200 {
    max-height: 200px;
}

.scrollable {
    overflow-y: scroll;
}

md-select-value{
    max-width: 90% !important;
}