.gallery-box {
  position: relative;
  text-align: center;

  .gallery-box-img {
    border-radius: 10px;
  }

  .gallery-box-count {
    top: 20%;
    position: absolute;
    font-size: 25px;
    font-weight: 700;
    left: 25%;
    color: #515151;
    background-color: #cbccccba;
    margin: 0;
    padding: 0;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    text-align: center;
    line-height: 1.5;
  }

  .gallery-box-header {
    margin: 0;
    font-weight: 700;
  }
}

.receiving-approve-box {
  padding: 5px;
  margin: 5px 0;

  .receiving-approve-box-header {
    text-align: center;
    font-weight: 700;
  }
  .receiving-approve-box-row {
   padding: 0 10px;
  }
}
.offer-receiving-item-conflict{
  background-color: #D99B9BAF;
}
.offer-receiving-item-approve{
  background-color: #eef5ec;
}
