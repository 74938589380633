
md-card-title {
  max-height: 53px;
}
md-card-footer {
  border-top:1px solid #eee;
}

.wms-setting-for-order-status-changes {
  margin: 10px 3px;

  .wms-setting-for-order-status-changes-item {
    height: 50px;
    display: flex;
    align-items: center;

    .wms-setting-for-order-status-changes-item-header {
      margin-left: 10px;
    }

    .wms-setting-for-order-status-changes-item-select {
      margin-right: 10px;
    }
  }

  .wms-setting-for-order-status-changes-item:nth-child(even) {
    background-color: #f4f4f4cc;
  }

}

.branch-content {
  margin: 10px 0;

  .branch-images {
    justify-content: space-around;

    .branch-image-action {
      margin-top: 5px;
    }

    .branch-image-content {
      height: 60px;
      border: 1px solid #d7dee4;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    label[for=file-logo],
    label[for=file-signature],
    label[for=file-watermark] {
      width: 100%;
    }

    .branch-image-content-delete {
      button {
        background-color: #ff4b4b;
        height: 30px;

        &:hover {
          background-color: #676767;

        }

        md-icon {
          color: white;
        }
      }
    }
  }

}

.branch-action {
  justify-content: space-between;
  align-items: center;
  display: flex;
  // color: white;

  .branch-action-save {
    // background-color: #8ba646;
  }

  .branch-action-cancel {
    // background-color: #f35f5f;
  }
}

.branches-list {
  margin: 10px 10px;

  .branches-list-header {
    text-align: center;
  }

  .branches-list-content-group {
    overflow: scroll;
    max-height: 320px;

    .branches-list-content {
      div {
        align-items: center;

        display: flex;
      }

      div:not(:first-child) {
        text-align: center;
        justify-content: center;
      }

    }
  }
}

.branch-form-detail{
  border: 0.5px solid #80808082;
  border-radius: 6px;
  padding: 15px;
  margin: 10px;
}
@media (max-width: 599px) {
  .branch-content {
    display: block;
    .branch-images {
      .branch-images-type{
        min-height: 100%;
      }
      .branch-image-content {
        min-height: 40%;
      }


    }
  }
}

.exchange-rate-currency{
  position: absolute;
  right: 10px;
  top: 7px;
  color: gray;
  font-weight: 700;
}
.current-exchange-rate-currency{
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: gray;
  padding-top: 13px;
}
.changed-exchange-rate{
  background-color: #d2d2812e;
}
.new-exchange-rate{
  background-color: rgba(129, 210, 142, 0.18);
}