.search-result-box {
  margin: 12px;
  font-size: 19px;
  text-align: center;
}

.md-circle-button {
  margin: 0 !important;
  padding: 0 !important;
  width: unset !important;
  height: 30px;
}

.cart-quantity-input {
  width: 56%;
  height: 30px;
  border: none;
  border-bottom: 1px solid #e3e3e3;
  color: gray;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
}


.analytics-header {
  margin: 5px 0 !important;
}

.analytics-content {

  margin: 10px 0;

  p {
    font-weight: 700;
    margin: 12px 0;
  }

  .analytics-item {
    border: 0.5px solid #d7d7d7;
    border-radius: 5px;
    margin-right: 1px;
    text-align: center;

    &.lastBuying {
      background-color: #4a7f67;
      color: white;
    }
  }
}

.separator {
  border-right: 1px solid grey;
  margin: 0 5px;
  height: 100%;
}

.offer-process-complete-color {
  color: #d3ffdd !important;
}

.offer-process-complete-background {
  background-color: #d3ffdd !important;
}


.offer-process-not-complete-color {
  color: #f48080 !important;
}

.offer-process-not-complete-background {
  background-color: #fff9cb !important;
}

.offer-receiving-item {

}
.receiving-nodes {
  .node-header {
    font-weight: 700 !important;
  }

  .node-content {
    font-size: 25px;
    font-weight: 700;
    &.node-content-lot-6{
      width: 98%;
    }
  }

  .node-empty {
    background-color: #dbdbdb70;
    color: #998989;
    border: 1px dashed;
  }

  .node-content-empty-message {
    font-size: 10px;
  }

  .node-unit {
    border-radius: 7px;
    margin-right: 2px;
  }

  .first-node {
    background-color: #e0e0e0;
  }

  .last-node {
    margin-right: 2px;
  }

  .node-wait {
    border: 1px solid #ccc !important;
    background-color: #fff !important;
    text-align: center;
    margin: 5px;
    border-radius: 5px;
  }
  .node-done {
    background-color: #cde9ca !important;
    text-align: center;
    margin: 5px;
    border-radius: 5px;
  }
  .node-reject {
    background-color: #e9cacf !important;
    text-align: center;
    margin: 5px;
    border-radius: 5px;
  }
  .node-missing {
    background-color: #e3e3e3 !important;
    text-align: center;
    margin: 5px;
    border-radius: 5px;
  }
  .node-total {
    background-color: #ffffae !important;
    text-align: center;
    margin: 5px;
    border-radius: 5px;
  }





  .lot-list-node {
    background-color: #4399ef !important;
  }

  .images-list-node {
    height: 100% !important;
  }
}

.receiving-selected-product {
  .change-product {
    background-color: #ebffd3;
    margin-bottom: 20px;
  }
}

.receiving-actions {
  margin-top: 25px;

  button {
    min-height: unset !important;
  }
}

.procurement-product-item {
  padding: 5px;
  border: 1px solid #dedede;
  border-radius: 6px;
  position: relative;
  margin: 10px 0 ;
  .procurement-product-item-select {
    position: absolute;
    color: green;
  }
}

.recipe-item:nth-child(odd) {
  background-color: #f5faff;
}

.recipe-item:nth-child(even) {
  background-color: #fff;
}

.purchase-request-item-statistic {
  border: 1px dashed #cacaca;
  font-weight: 700;
  text-align: center;

  .purchase-request-item-statistic-data {
    font-size: 20px;
  }
}

.md-pressed {
  background: #ccc;
}
