/* theme uyumluluk için kaldırılmalı*/
.bg-dashboard {
	background-color: #f6f8fa !important;
	color: #343434;
}

.bg-white {
	background-color: #fff !important;
	height: 370px;
	margin-bottom: 30px;
}
.header {
	margin: 0px !important;
	padding: 0px !important;
	color: #ff6600 !important;

	.mini-btn {
		margin: 0px;
	}
}

.header h3 {
	margin-left: 15px !important;
}

.header h3 button,
.header h3 md-select {
	display: none;
}

.bg-white:hover {
	.header h3 button {
		display: block;
	}

	.header h3 md-select {
		display: block;
	}
}
.dashboard-table td,
.dashboard-table th {
	border: 1px solid #f6f8fa;
}
