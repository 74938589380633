@import url("../../../../node_modules/angular-ui-carousel/dist/ui-carousel.css");
.dropdown-toggle {
  width: 100%;
}

html, body {
  position: fixed;
  //overflow-y: scroll;
  width: 100%;
}


.dropdown-toggle {
  width: 100%;
}

.open > .dropdown-menu {
  width: 100% !important;
}

.multiselect-parent {
  width: 100% !important;
}
/*
@media screen and (max-width: 768px){
  #carouselImage{
    transition: 0.5s all ease-in-out;
  }

  ui-carousel:hover #carouselImage{
    transform: scale(1.5);
  }
}
*/

.dropdown-menu-form {
  /* height: 510px!important;*/
}


.tag-template .left-panel {
  float: left;
}

.tag-template .left-panel img {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

.tag-template .right-panel {
  float: left;
  margin-left: 5px;
}

.autocomplete-template .left-panel {
  float: left;
}

.autocomplete-template .left-panel img {
  width: 48px;
  height: 48px;
  vertical-align: middle;
}

.autocomplete-template .right-panel {
  float: left;
  margin-left: 5px;
  margin-top: 7px;
}

.autocomplete-template .right-panel span:first-child {
  font-size: 16px;
}

.autocomplete-template .right-panel span:nth-child(2) {
  font-size: 14px;
  color: gray;
}

.autocomplete-template .right-panel span:last-child {
  display: block;
  font-size: 14px;
  font-style: italic;
}

.table-striped > tbody > tr.bgSuccess {
  //background-color: #28a745!important;
  //color: #fff;
  background-color: #d6f5d6 !important;
}

.table-striped > tbody > tr.bgDanger {
  //background-color: #dc3545!important;
  //color: #fff;
  background-color: #f4c1c1 !important;
}

.whiteText {
  color: #FFF !important;
}

h5.price {
  margin: 0px !important;
  line-height: 1.6 !important;
}

.price-deleted {
  text-decoration: line-through;
}

.md-calendar-scroll-container {
  width: 100% !important;
}

.md-datepicker-input-mask {
  width: 308px !important;
}

.critical-stock-level-bar {
  .critical-stock-level-bar-select:hover {
    cursor: pointer;
    text-decoration: none;
    color: black;
  }

  .critical-stock-level-bar-select {
    color: black;
    font-weight: 500;
  }

  .critical-stock-level-bar:hover {
    cursor: pointer;
    text-decoration: none;
    color: black;
  }

  .critical-stock-level-bar-items {
    font-weight: 500;
    margin-left: 21px;
    margin-top: 5px;

    .critical-stock-level-bar-item {
      margin-bottom: 3px;
      border-radius: 5px;
    }

    .critical-stock-level-bar-item:hover {
      background: #f3f3f3;
    }
  }

  .critical-stock-level-bar-items:hover {
    cursor: pointer;
  }

  .critical-stock-level-bar-item-success, .critical-stock-level-bar-item-success > md-icon {
    color: #00a65a !important;

  }

  .critical-stock-level-bar-item-warning, .critical-stock-level-bar-item-warning > md-icon {
    color: #979656 !important;

  }

  .critical-stock-level-bar-item-danger, .critical-stock-level-bar-item-danger > md-icon {
    color: #b6012f !important;

  }
}

.label-custom-resourcetype {
  background-color: #9f64bf;
}
.label-custom-darkcyan {
  background-color: #008080;
}

.variation-adding-table {
  overflow-y: scroll;

  table {
    tbody {
      tr {
        td {
          input {
            width: 100%;
          }

          button {
            md-icon {
              color: #fb4a4a;
            }
          }
        }
      }
    }
  }
}

.product-search-box {
  position: relative;

  md-list {
    position: absolute;
    height: 188px;
    overflow: scroll;
    z-index: 9;
    background-color: white;
    width: 100%;
  }
}

.product-channel-list {
  margin: 10px 5px 10px 0;

  .product-channel-list-header {
    text-align: center;
    margin-bottom: 5px;
    align-items: center;
  }

  .product-channel-list-rows {
    .product-channel-list-row {
      align-items: center;
      &:nth-child(odd) {
        background-color: #e1e1e18a;
      }

      &:nth-child(even) {
        background-color: #f4f4f4cc;
      }
    }
  }

  .text-align-center{
    justify-content: center;
    text-align: center;
  }
}

.stocktaking-other-shelf{
  padding: 5px;
  &:nth-child(odd) {
    background-color: #e1e1e18a;
  }

  &:nth-child(even) {
    background-color: #f4f4f4cc;
  }
  .stocktaking-other-shelf-contains{
    background-color: #52886e !important;
  }
}

.md-select-value {
  min-width: auto;
}

.from-shelf{
  background-color: red!important;
  color: white;
}
.to-shelf{
  background-color: #2dd881!important;
  color: white;
}

.sub-title{
  padding: 2px 0;
  margin: 5px 0 0;
  font-size: 16px;
  color: #ff6600 !important;
  font-weight: bolder;
  text-align: left;
}

.sub-location{
  text-align: center;
  padding-block: 4px;
  border:1px solid #eee;
}
.stock-card-shelf{
  font-size: x-large;
  font-weight: bolder;
}
.stock-shelf{
  color: #00a65a;
}
.preStock-shelf{
  color: #DF2E38;
}
.reservedStock-shelf{
  color: #b14698;
}
.stock-card-shelf-quantity{
  text-align: center;
  font-size: 16px;
  border: 1px solid #cccccc;
  border-top: none;
}

/* loading bar css : https://cdnjs.cloudflare.com/ajax/libs/angular-loading-bar/0.9.0/loading-bar.min.css*/

#loading-bar, #loading-bar-spinner {
  pointer-events: none;
  -webkit-pointer-events: none;
  -webkit-transition: 350ms linear all;
  -moz-transition: 350ms linear all;
  -o-transition: 350ms linear all;
  transition: 350ms linear all
}

#loading-bar-spinner.ng-enter, #loading-bar-spinner.ng-leave.ng-leave-active, #loading-bar.ng-enter, #loading-bar.ng-leave.ng-leave-active {
  opacity: 0
}

#loading-bar-spinner.ng-enter.ng-enter-active, #loading-bar-spinner.ng-leave, #loading-bar.ng-enter.ng-enter-active, #loading-bar.ng-leave {
  opacity: 1
}

#loading-bar .bar {
  -webkit-transition: width 350ms;
  -moz-transition: width 350ms;
  -o-transition: width 350ms;
  transition: width 350ms;
  background: #ff8001;
  position: fixed;
  z-index: 10002;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  border-bottom-right-radius: 1px;
  border-top-right-radius: 1px
}

#loading-bar .peg {
  position: absolute;
  width: 70px;
  right: 0;
  top: 0;
  height: 2px;
  opacity: .45;
  -moz-box-shadow: #ff8001 1px 0 6px 1px;
  -ms-box-shadow: #ff8001 1px 0 6px 1px;
  -webkit-box-shadow: #ff8001 1px 0 6px 1px;
  box-shadow: #ff8001 1px 0 6px 1px;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%
}

#loading-bar-spinner {
  display: block;
  position: fixed;
  z-index: 10002;
  top: 10px;
  left: 10px
}

#loading-bar-spinner .spinner-icon {
  width: 14px;
  height: 14px;
  border: 2px solid transparent;
  border-top-color: #ff8001;
  border-left-color: #ff8001;
  border-radius: 50%;
  -webkit-animation: loading-bar-spinner 400ms linear infinite;
  -moz-animation: loading-bar-spinner 400ms linear infinite;
  -ms-animation: loading-bar-spinner 400ms linear infinite;
  -o-animation: loading-bar-spinner 400ms linear infinite;
  animation: loading-bar-spinner 400ms linear infinite
}

@-webkit-keyframes loading-bar-spinner {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-moz-keyframes loading-bar-spinner {
  0% {
    -moz-transform: rotate(0);
    transform: rotate(0)
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-o-keyframes loading-bar-spinner {
  0% {
    -o-transform: rotate(0);
    transform: rotate(0)
  }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-ms-keyframes loading-bar-spinner {
  0% {
    -ms-transform: rotate(0);
    transform: rotate(0)
  }
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0)
  }
  100% {
    transform: rotate(360deg)
  }
}

#loading-bar .bar {
  height: 5px !important;
}
.stock-management-box-frame{
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 5px;
  .stock-management-box-header{
    text-align: center;
    font-weight: bolder;
    border-bottom: 1px dotted #ccc;
    border-radius: 3px;
    margin-bottom: 10px;
  }
  .stock-management-box-body{
    margin: 10px 1px;
    min-height: 50px !important;
  }
  .stock-management-box-footer{}

}
.stock-management-box-frame-green{
  background-color: rgba(0, 166, 22, 0.53);
}
.stock-management-box-frame-red{
  background-color: rgba(166, 0, 0, 0.53);

}

tags-input .autocomplete .suggestion-item.selected {
  color: #fff;
  background-color: #ccc !important;
}
