.shipping-action-list-row:nth-child(odd) {
  background-color: #e1e1e18a;
}

.shipping-action-list-row:nth-child(even) {
  background-color: #f4f4f4cc;
}

.shipping-action-current-row {
  font-weight: 700 !important;
  font-size: 17px !important;
}

.shipping-action-list-row {
  line-height: 50px;
}

.shipping-action-approve-row {
  font-weight: 700;
  background-color: #ffebb6 !important;
}

.shipping-action-done-row {
  font-weight: 700;
  background-color: #4caf50 !important;
}

.shipping-action-select {
  background: #f0efef;
  padding: 15px 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.selected-box {
  border-radius: 7px;
  border: 1px solid #d9d9d9;
  margin-right: 10px;

  .selected-content {
    min-height: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
  }

  .selected-header {
    text-align: center;
  }
}

.shipping-action-list-header {
  font-size: 15px;
  font-weight: 700;
}

.shipment-message-box {
  max-height: 80px;
  display: ruby-text;
  overflow-y: scroll;
}

.shipment-dashboard-box-status-header {
  font-weight: 700;
  text-align: left;
}