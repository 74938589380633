.LoginForm-inputContainer{
    margin-top: 10px;
    margin-bottom: 0px;
    width: 80%;
    min-width: 300px;
}

.LoginForm-submit{
    margin-bottom: 40px;
    margin-left: 0;
    width: 100%;
    padding: 5px 0;
}


.password-container {
    position: relative;
}

.password-container input {
    padding-right: 36px; /* İkon için boşluk oluştur */
}

.password-toggle-icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54); /* Angular Material ikon rengi */
}
