.production_table {
	font-size:20px!important;
	color:#fff;
}
h2 {
	color:#000!important;
}

table.production_table thead {
	background-color:#ff6600;
}

td.bg-pink {
	background-color:#d8387c!important;
}

td.bg-black {
	background-color:#000000!important;
}