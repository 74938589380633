.selectedDiv{
	border:solid rgb(3,155,229) 1px;
	float:left;
	margin:2px;
	padding:2px 15px;
}

.selectedDiv a{
	cursor:pointer;
	font-weight:bold;
}

.btn-custom-info{
	background-color: rgb(233 200 118 / 89%) !important;
	md-icon{
		color: #6f6a6a !important;
	}
}

@media print {
	.onlyPrint{
		width: 50%;
	}
}

.self-assign-group{
	display: flex;
	align-items: center;
	justify-content: space-between;

	.self-assign-blog {
		min-height: 250px;
		max-height: 250px;
		overflow-x: scroll;
		border: 1px solid #eeeeee;
		border-radius: 3px;
		.self-assign-blog-item-selected{
			background-color: rgba(40, 250, 178, 0.6);
		}
		.self-assign-blog-item{
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 2px;
			padding: 5px;
			cursor: pointer;
			&:hover{
				background-color: rgba(199, 229, 221, 0.38);

			}
		}
	}
}

.shelf-quantity-types{
	border: 1px solid #ccc;
	border-right: none ;
	min-width: 25px;
	text-align: center;
	&:last-child {
		border-right: 1px solid #cbcccb;
	}
}

.successRemove {
	background-color:  #37c400;
	border: 1px solid #FFFFFF;
}

.errorRemove {
	background-color: #FF5722;
	border: 1px solid #dddddd;
}

.shelf-item-list-row{
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 0;
	border-bottom: 1px solid #eee;
	&:nth-child(odd) {
		background-color: #fff;
	}
	&:nth-child(even) {
		background-color: #fff;
	}
}

.shelf-stock{
	height: 20px;
	width: 80%;
	margin: 10%;
	border: 1px solid black;
}

.chart-container {
	position: relative;

	&#chart-container-doughnut {
		height: 250px;
		width: 200px;
	}

	&#radar {
		height: 350px;
		width: 250px;
	}
}

.shelf-info-row{
	margin-bottom: 10px;
	align-items: center;
}
