body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

::-webkit-input-placeholder,
:-ms-input-placeholder,
::placeholder,
span,
div,
button,
textarea {
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

#app-shell-header {
    background-color: white;
    height: 64px;
}
#app-shell-header img {
    padding: 13px 0;
    margin-left: 5%;
}
#app-shell-content {
    background-color: #00a4c6;
    min-height: 600px;
}

md-toast.md-success-theme {
    .md-toast-content {
        background-color: green;
    }
}

.table-outer {
    padding: 0;
    margin: 0;
    /*padding-left: 1px !important;*/
    overflow: hidden;
    /*height: 700px;*/
    overflow-x: scroll;
    overflow-y: scroll;
}

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #f9f9f9 !important;
}

.table-striped > tbody > tr:nth-of-type(even) {
    background-color: #f5f5f5 !important;
}

.table-striped > tbody > tr:hover {
    background-color: #fff !important;
    transition: all 0.5s;
}

.table > thead > tr > th {
    border-bottom: 0px !important;
}

md-input-container.md-input-has-value label:not(.md-no-float) {
    -webkit-transform: translate3d(0, 6px, 0) scale(0.75) !important;
    transform: translate3d(0, 6px, 0) scale(0.75) !important;
}

@media only screen and (max-width: 1024px) {
    .search-box {
        margin-left: 2px !important;
        margin-right: auto !important;
        width: 100% !important;
    }
    .well {
        max-width: 100% !important;
        min-height: 240px;
    }
    .RegisterForm-inputContainer {
        min-width: auto !important;
    }
    .md-button {
        white-space: normal !important;
        line-height: 2em;
    }
}

/* This will disable the panel animation */
md-tabs [role="tabpanel"] {
    transition: none;
}

/* This will disable the `ink-bar` animation (border-bottom of selected tab) */
md-tabs md-ink-bar {
    transition: none;
}
