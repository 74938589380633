::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #fff;
}


::-webkit-scrollbar-thumb {
  background: #c9d5f5; //#888;
  width: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ff6600;
}

md-card md-card-title {
  padding: 0;
  border-bottom: 1px solid #e3e5e7;
}

md-card md-card-title h2 {
  margin: 2px;
  padding: 12px;
  font-size: 18px;
  color: #ff6600 !important;
  font-weight: bolder;
}

.empty-dashboard {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #acacac;
  background: #f6f6f6;
  margin: 2px;
  label {
    position: relative;

    small {
      color: white;
      position: absolute;
      top: 12px;
      right: 50.3%;
    }

    md-icon {
      font-size: 35px;
      color: #acacac;
      margin-right: 20px;
    }
  }
}

.daily-wms {
  md-card {
    min-height: 100px !important;
    max-height: 100px !important;
    margin-bottom: 10px;
  }

  md-card-content {
    margin-top: 10px;
    padding: 0;

    .no-content-message {
      margin: 0 10px;
      font-size: 10px;
      color: #7e7e7e;
    }
  }

  .daily-wms-item {
    .daily-wms-item-title {
      margin: 1px;
      padding: 10px 6px;
      font-size: 12px;
      color: #ff6600 !important;
      font-weight: bolder;
      max-height: 35px;
    }

    .dashboard-content-icon {
      display: flex;
      align-items: center;

      md-icon {
        font-size: 40px;
        height: auto;
      }
    }

    .dashboard-content-item {
      display: flex;
      align-items: center;
      justify-content: center;

      .dashboard-content-item-subject {
        font-size: 20px;
        margin: 0 10px;
      }

      .dashboard-content-item-subject-detail {
        font-size: 15px;
        width: 90%;

        label {
          margin-bottom: 1px;
        }

        small {
          font-size: 65%;
        }

        .dashboard-content-item-subject-detail-wait {
          color: #6d6b6b;
          font-weight: 700;
        }

        .dashboard-content-item-subject-detail-complete {
          color: #b1b1b1;
        }

        md-divider {
          margin-bottom: 0;
        }
      }
    }
  }
}

.general-wms {
  md-card-content {
    margin-top: 10px;
  }

  .general-wms-filter {
    margin-top: 10px;
    float: right;
  }
}

.dashboard-updated-at {
  margin-right: 10px;
  font-size: 10px;
  color: #adadad;
}

.stock-report {

  .stock-report-item {
    .stock-report-content-item-header {
      text-align: center;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      background: white;
      width: 100%;
    }

    .stock-report-content-item-row {
      padding: 0 10px;

    }

    .stock-report-content-item-header,
    .stock-report-content-item-row {
      min-height: 55px;
      align-items: center;
    }

    .stock-report-content-item-header > div,
    .stock-report-content-item-row > div {
      margin: 0 10px;
    }

    .stock-report-content-item-row:nth-child(odd) {
      background-color: #e1e1e18a;
    }

    .stock-report-content-item-row:nth-child(even) {
      background-color: #f4f4f4cc;
    }

    .stock-report-content {
      max-height: 500px;
      overflow: hidden;
      overflow-y: scroll;
    }

    md-card {
      min-height: 600px;
    }

  }
}

.sales-report {

  .sales-report-item {
    .sales-report-content-item-header {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      background: white;
      width: 100%;
    }

    .sales-report-content-item-row {
      padding: 0 10px;

    }

    .sales-report-content-item-header,
    .sales-report-content-item-row {
      min-height: 55px;
      align-items: center;
    }

    .sales-report-content-item-header > div,
    .sales-report-content-item-row > div {
      margin: 0 10px;
    }

    .sales-report-content-item-row:nth-child(odd) {
      background-color: #e1e1e18a;
    }

    .sales-report-content-item-row:nth-child(even) {
      background-color: #f4f4f4cc;
    }

    .sales-report-content {
      max-height: 185px;
      overflow: hidden;
      overflow-y: scroll;
    }

    md-card {
      min-height: 300px;
    }

  }
}
