md-datepicker {
background-color: transparent!important;
}

.empty-state{
border: 5px dotted #efefef;
    padding: 1.5rem 1rem 1rem 1rem;
    text-align: center;
    color: #818a91;
}
.ui-grid-header {

}
.last-order-price {
  background-color: #37c400;
}
.before-order-price {
  background-color: #dddddd;
}
.grid{
	height:600px!important;
}

.orangeBg-add, .orangeBg-remove,
.redBg-add, .redBg-remove,
.blueBg-add, .blueBg-remove,
.whiteBg-add, .whiteBg-remove {
  transition: background-color 1000ms linear;
}

.orangeBg,
.orangeBg-add.orangeBg-add-active {
    background-color: rgb(3,155,229);
    p.lead{
    	color:#fff!important;
    }

}
.redBg,
.redBg-add.redBg-add-active {
    background-color: #ff0000;
}
.blueBg,
.blueBg-add.blueBg-add-active {
    background-color: #0000ff;
}
.whiteBg,
.whiteBg-add.whiteBg-add-active {
    background-color: #ffffff;
}

.align-right {
	text-align: right;
}

.align-left {
	text-align: left;
}

.md-tab {
    text-transform: none!important;
}
.ml-3 {
    margin: 5px 10px 0px 10px;
}

.menudemoBasicUsage .md-menu-demo {
  padding: 24px; }

.menudemoBasicUsage .menu-demo-container {
  min-height: 200px; }

.md-open-menu-container {
    opacity: 1!important;
}

.empty-state {
        color: rgba(0,0,0,0.87)!important;
}

.ui-grid-selection-row-header-buttons::before {
    opacity: 0.7;
}

.ui-grid-selection-row-header-buttons.ui-grid-row-selected::before, .ui-grid-selection-row-header-buttons.ui-grid-all-selected::before {
    opacity: 1;
}


.action-organization-package {
  .action-organization-package-icon {
    width: 100%;
    align-items: center;
    text-align: center;
    box-shadow: rgb(17 17 26 / 10%) 0 1px 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .action-organization-package-list {
    padding: 0;

    .action-organization-package-list-item {
      padding: 0;

      h3 {
        font-size: 12px;
        font-weight: 700;
      }

      h4 {
        font-size: 10px;
        font-weight: 500;
      }

      p {
        font-size: 8px;
        font-weight: 400;
      }
    }

    .-enough{
      background-color: #a7e6a769;
    }
    .-not-enough{
      background-color: #ffb5b536;
    }

    .action-organization-package-list-item-quantity {
      display: flex;
      align-items: center;
      text-align: center;

      h4 {
        font-size: 12px;
        font-weight: 700;
      }

    }
  }
}

.label{
  &.offer-item-process-done{
    background-color: #5cb85c !important;
  }
  &.offer-item-process-required{
    background-color: #9f9f9f !important;
  }
  &.offer-item-process-not-required{
    background-color: #cccccc !important;
    color: #ffffff;
  }
}

.offer-item-process-input{
  border: 1px solid #eee;
  border-radius: 5px;
  .left-input{
    label{
      text-align: end !important;
    }
    md-input-container label {
      transform-origin: right top;
    }
    input{
      text-align: end !important;
    }
    .offer-item-process-action{
      text-align: end !important;
    }
  }

}

.relation-table{
  .relation-table-header{
    line-height: 3;
    font-size: 15px;
    font-weight: 700;
    border-bottom: 1px solid;
  }
  .relation-table-row{
    line-height: 2;
    margin: 0 1px;

  }
  .relation-table-row:nth-child(odd) {
    background-color: #e1e1e18a;
  }
  .relation-table-row:nth-child(even) {
    background-color: #f4f4f4cc;
  }
}

.action-active{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: repeating-linear-gradient(125deg, transparent, transparent 5px, red 5px, red 10px);
  opacity: 0.3;
  z-index: 9999;
}

.invoice-detail-tr__ {
  position: relative;
  padding: 5px 5px;
  border-bottom: 1px solid #eee;
}
.invoice-detail-tr {
  position: relative;
  padding: 5px 5px;
  border-bottom: 1px solid #eee;
}
.invoice-detail-tr-add {
  position: relative;
  padding: 5px 5px;
  border-bottom: 1px solid #eee;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, #dff5e1 0, #dff5e1 10px);
}
.invoice-detail-tr-change {
  position: relative;
  padding: 5px 5px;
  border-bottom: 1px solid #eee;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, #f8f4dd 0, #f8f4dd 10px);
}
.invoice-detail-tr-delete {
  position: relative;
  padding: 5px 5px;
  border-bottom: 1px solid #eee;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, #f8e6e0 0, #f8e6e0 10px);
}



.icon-background {
}
.icon-background-add {
}
.icon-background-change {
}
.icon-background-delete {
}

.icon-background::before {

}
.icon-background-add::before {
  content: "\e145"; /* Kullanmak istediğiniz Material Icon kodunu buraya koyun */
  font-family: "Material Icons";
  font-size: 48px;
  color: #8BC34A;
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
}
.icon-background-change::before {
  content: "\e863"; /* Kullanmak istediğiniz Material Icon kodunu buraya koyun */
  font-family: "Material Icons";
  font-size: 48px;
  color: #f6d200;
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
}
.icon-background-delete::before {
  content: "\e15b"; /* Kullanmak istediğiniz Material Icon kodunu buraya koyun */
  font-family: "Material Icons";
  font-size: 48px;
  color: #FF5722;
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
}

