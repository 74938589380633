
body {
  /*height: auto !important; bottomSheetin sorun açılması adına kapatıldı */
  top: 0 !important;
  width: 100% !important;
  /* theme uyumluluk için kaldırılmalı*/
  background: #f6f8fa !important;
  font-family: Arial, Helvetica, sans-serif;
}
.falanfilan{font-family: "Courier New", Courier, monospace}
.md-toast-animating {
  overflow-y: scroll !important;
}

#loading-bar-spinner {
  display: block;
  position: fixed;
  z-index: 100000002 !important;
  top: 10px;
  right: 10px;
}

md-content {
  //min-height: 960px !important;
  //background-color: #f8f8f8 !important;
  /* theme uyumluluk için kaldırılmalı*/
  //background-color: #f5f5f5 !important;
  //min-height: 90vh !important;

  //height:90vh;
  //overflow:auto;
}
.md-content-scroll {
  height: 85vh;
  overflow: auto;
}

/* Tablet ve küçük masaüstü ekranlar için */
@media screen and (max-height: 1024px) {
  .md-content-scroll {
    height: 85vh;
  }
  .md-content-scroll-without-header {
    height: 96vh;
  }
}

/* Orta boy ekranlar için (yükseklik 768px ve altı) */
@media screen and (max-height: 768px) {
  .md-content-scroll {
    height: 82vh;
  }
  .md-content-scroll-without-header {
    height: 96vh;
  }
}

@media screen and (max-height: 750px) {
  .md-content-scroll {
    height: 72vh;
  }
  .md-content-scroll-without-header {
    height: 96vh;
  }
}
/* Küçük ekranlar için (yükseklik 650px ve altı) */
@media screen and (max-height: 650px) {
  .md-content-scroll {
    height: 68vh;
  }
  .md-content-scroll-without-header {
    height: 96vh;
  }
}
@media screen and (max-height: 550px) {
  .md-content-scroll {
    height: 64vh;
  }
  .md-content-scroll-without-header {
    height: 96vh;
  }
}
@media screen and (max-height: 450px) {
  .md-content-scroll {
    height: 62vh;
  }
  .md-content-scroll-without-header {
    height: 96vh;
  }
}


md-toast {
  position: fixed;
  z-index: 100000002 !important;
  width: auto !important;
  height: 58px !important;
  top: 0px;
  right: 10px;
}

.md-button {
  text-transform: none !important;
}
.md-primary {
  background-color: #375cdc !important;
}

#loading-bar-spinner .spinner-icon {
  width: 19px;
  height: 19px;

  border: solid 3px transparent;
  border-top-color: #FF6600;
  border-left-color: #ff6600;
  border-radius: 50%;

  -webkit-animation: loading-bar-spinner 400ms linear infinite;
  -moz-animation: loading-bar-spinner 400ms linear infinite;
  -ms-animation: loading-bar-spinner 400ms linear infinite;
  -o-animation: loading-bar-spinner 400ms linear infinite;
  animation: loading-bar-spinner 400ms linear infinite;
}

#editor {
  height: 375px;
}

.ql-showHtml:after {
  content: "[kaynak]";
}


.container-fluid {
}
md-tooltip div {
  height: auto !important;
  font-size: 15px !important;
  z-index: 100000003;
}

md-tabs md-ink-bar {
  background-color: #018cd1 !important;
  color: #018cd1 !important;
}
/* theme uyumluluk için kaldırılmalı*/
md-tabs {
  background-color: #fff;
}
.newActionLine{
  background-color: #66BB6A;
}
.deleteUpdate {
background-color: #FF9E80 !important;
}
.actionLineUpdate{
  background-color: #81D4FA;
}
.successImage{
  border:2px solid #00a65a;
}
.errorImage{
  border:2px solid #d7dee4;
}

.well {
  background-color: #ffffff !important;
  border: 1px solid #ccc !important;
  border-radius: 0;
  box-shadow: none !important;
}

md-select-menu md-content {
  min-width: 136px !important;
  min-height: 48px !important;
  max-height: 256px !important;
  overflow-y: auto !important;
}

.md-datepicker-input-container {
  //width: 80%;
}

md-datepicker {
  /*width: 100% !important;*/
}
.md-datepicker-input {
  //width: 90% !important;
}

.scrolling-div {
  overflow: hidden;
  height: 700px;
  overflow-x: scroll;
  overflow-y: scroll;
}
.scrolling-table {
  position: absolute;
}
.label {
  float: left;
  margin: 4px;
}

/* theme uyumluluk için kaldırılmalı*/
md-card {
  background-color: #fff;
  margin:5px;
  h2:first-of-type {
    margin-top: 0;
  }
}

md-card md-card-content {
  /*padding: 10px;*/
  padding: 5px;
}

md-input-container .md-errors-spacer {
  display: none;
}
md-datepicker .md-errors-spacer {
  height: 0;
  float: none;
}

md-divider {
  margin-bottom: 10px;
}

pagination md-input-container {
  margin:3px 0 0;
}

md-toast.md-warn-theme .md-toast-content {
  background-color: rgb(255,82,82);
  color:rgba(0,0,0,0.87);
}

md-card-footer {
  padding: 10px !important;
}

md-input-container {
  margin-left: 0px !important;
}

md-card-actions {
  margin: 0 !important;
}

/* theme uyumluluk için kaldırılmalı*/
/*
.alert {
  padding: 7px !important;
  margin-top: 10px !important;
  margin-bottom: 2px !important;
}

.alert-info {
  color: #262626;
  background-color: #f2f5fc;
  border-color: #f2f5fc;
}
 */

.md-alert-info {
  padding: 2px !important;
 // margin-top: 5px !important;
 // margin-bottom: 5px !important;
  font-style: italic;
}

.md-alert-info p {
  margin: 0;
  color: grey;
}

.md-button {
  border-radius: 5px !important;
  /*min-height: 25px !important;*/
}
.mini-btn {
  margin: 0px 3px 0px 3px;
  min-width: 24px !important;
}

.mini-btn-icon {
  font-size: 10px !important;
  padding: 0 4px !important;
  border-radius: 5px !important;
  min-height: 25px !important;
  line-height: 30px !important;
}
.md-button.md-icon {
  font-size: medium !important;

}
/* theme uyumluluğu için kaldırılmalı*/
.md-button[disabled] {
  background:#e4e4e4 !important;
}

.d-button {
  padding: 0 12px !important;
  margin: 6px 0px !important;
  min-width: 49px!important;
  border-radius: 0 !important;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top:1px solid #dbdbdb;
  background-color: white;
  text-align: right;
  padding: 0px;
}

@keyframes spin-right {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes spin-left {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin-left {
  animation-name: spin-left !important;
  animation-duration: 4000ms !important;
  animation-iteration-count: infinite !important;
  animation-timing-function: linear !important;
}

.spin-right {
  animation-name: spin-right;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.md-none {
  display: none !important;
}

md-dialog {
  min-width: 99% !important;
  /*min-height: 75% !important;*/
  /*margin-bottom: 10px;*/
}
.md-dialog-container {
  align-items: end !important;
}

tags-input .tags {
  border: 1px solid #d7dee4 !important;
  border-radius: 3px;
  box-shadow: none !important;
  padding: 0 !important;
  height: 34px !important;
}
tags-input .tags input, tags-input .tags input:focus {
  border: 0 !important;
  padding: 0 4px !important;
}
tags-input .host {
  margin: 0 !important;
}

.product-image-gallery {
  display: flex;
  margin: 0 8px;
  padding: 0;
  overflow: auto;
  list-style-type: none
}

.product-image-gallery li {
  margin: 2px
}

.product-image-gallery li img {
  height: 180px;
  border:1px solid #eee;
}


.image-zoom {
  height: 80px;
  width: auto;
  /* ease | ease-in | ease-out | linear */
  transition: transform ease-in-out 0.3s;
}
.image-zoom:hover {
  position:absolute;
  padding-left: 25px;
  border:1px solid #eee;
  transform: scale(3);
}

.image-shadow {
  /*
  -webkit-box-shadow: 0px 0px 27px -12px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 27px -12px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 27px -12px rgba(0,0,0,0.75);
   */

  border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  border: 1px solid #d7dee4
}

.product-image-gallery {
  display: flex;
  margin: 0 8px;
  padding: 0;
  overflow: auto;
  list-style-type: none
}

.product-image-gallery li {
  margin: 2px
}

.product-image-gallery li img {
  height: 180px;
  border:1px solid #eee;
}

md-input-container {
  margin: 18px 0 6px !important;
  padding: 0 4px !important;
}

md-input-container .md-input {
  height: 34px;
  border:1px solid #d7dee4 !important;
  padding: 1px  10px !important;
  border-radius: 3px;
  font-weight: 500;
}

md-input-container .md-input:focus {
  color: #000 !important;
  border:2px solid #64b5f6 !important;
  padding: 1px  10px !important;
}

label  {
  font-weight: normal !important;
  color: #757575 !important;
  padding: 1px  10px !important;
  margin:0px !important;
}

label:focus  {
  margin-bottom: 0px !important;
  color:#000 !important;
}

md-input-container md-select .md-select-value {
  border:1px solid #d7dee4 !important;
  padding: 1px  10px !important;
  border-radius: 3px;
  height: 30px;
}
md-input-container md-select .md-select-value:focus {
  color: #000 !important;
  border:2px solid #64b5f6 !important;
  padding: 1px  10px !important;

}

form{
  margin: 1px 0 6px !important;
  padding: 0 4px !important;
}

form select {
  height: 34px;
  border:1px solid #d7dee4 !important;
  padding: 1px  10px !important;
  border-radius: 3px;
  font-weight: 500;
}

.recommended-shelf{
  background-color: #009688;
}

.action-prepare-list-action-type-content{
  padding: 10px;
  &:nth-child(odd) {
    background-color: #e1e1e18a;
  }

  &:nth-child(even) {
    background-color: #f4f4f4cc;
  }
  .action-prepare-list-action-type-content-header{
    min-height: 50px;
    align-items: center;
    justify-content: center;
    font-weight: 900;
  }
  .action-prepare-list-action-type-content-item{
    align-items: center;
    border: 1px solid grey;
    padding: 5px;
    min-height: 118px;
    .shelf-name{
      font-size: 12px;
    }
    .action-prepare-list-action-type-content-recommended-shelf{
      background: #9d9d18;
      min-height: 16px;
      align-items: center;
      text-align: center;
      display: flex;
      justify-content: center;
      border-radius: 5px;
    }
  }
}
.action-prepare-list-action-expire-date-info{
  font-size: 12px;
  justify-content: flex-end;
  text-align: right;
  margin-top: 5px;
}
.action-prepare-list-action-type-content-warning-shelf{
  background-color: #d9534f;
  padding: 2px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  color: white;
  border-radius: 7px;
  md-icon{
    color: white;
  }
}

.md-bottom-sheet {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  top: unset !important;
  transform: translate3d(0, 0, 0) !important;
}

.stocktaking-lot-box {
  border: 1px solid #d1dff4;
  border-radius: 5px;
  margin: 2px;

}

.img-fit-container {
  width: 200px;
  height: 150px;
  overflow: hidden;
}
.img-fit {
  padding: 2px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left top;
}

.label-recipe-btn {
  background: #f0ad4e;
  color:white;
  border-radius: .25em;
  font-weight: 400;
  padding: 0 0.3em
}

.label {
  margin: 0 3px;
}
