.tal {
	text-align: left !important;
	text-transform: none !important;
	&--active {
		font-weight: bold;
		background-color: #eee;
	}
	&--active-shortcut {
		background-color: rgba(158, 158, 158, 0.7);
	}
}

.ui-grid-footer-info {
	text-align: left !important;
}

input.searchInput::-webkit-input-placeholder {
	color: #fff;
}

.md-toolbar-tools {
	padding: 0 !important;
}

md-input-container.md-icon-right>md-icon:last-of-type {
	right: 6px !important;
}

.md-sidenav-right {
	height: fit-content !important;
}


.notification-badge {
	//width: 15px;
	padding:1px 5px;
	height: 15px;
	line-height: 15px;
	display: inline-block;
	border-radius: 75px;
	position: absolute;
	right: 0px;
	top: 0;
	color: #fff;
	background: #f51b00;
	font-size: 10px;
}

.mobile-sidebar{
	align-items: center;
	padding-right: 10px;
	h1{
		//justify-content: end;
	}
}


.sticky-button {
	position: fixed;
	right: 0;
	top: 65px;
	width: 40px;
	height: 40px;
	background-color: #e26e04a1;
	color: white;
	border: none;
	border-radius: 5px 0 0 5px;
	text-align: center;
	line-height: 40px;
	cursor: pointer;
	z-index: 999;
}
