.bg-warning {
  background-color: #fcf8e3 !important;
}

.bg-info {
  background-color: #d9edf7 !important;
}

td {
  vertical-align: middle !important;
}

@media(max-width: 600px) {
  .barcodeReader {
    width: 300px;
  }
}

.header {
  position: sticky;
  top: 0;
  background: #efefef;
  z-index: 1;
}

.table-container {
  height: 600px;
  overflow: auto;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}

#printer {
  display: none;
}

@media print {
  #prepare, #select, breadcrumb, md-toolbar, md-next-button, md-prev-button, md-button, md-pagination-wrapper, #md-card-title, .shelf-item-list, #notToPrint, .notPrint {
    display: none;
  }
  #printer {
    display: block;
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}

.slide-top {
  -webkit-animation: slide-top 0.54s cubic-bezier(1.000, 0.000, 0.000, 1.000) both;
  animation: slide-top 0.54s cubic-bezier(1.000, 0.000, 0.000, 1.000) both;
}

.placement-message-box {
  position: absolute;
  right: 10px;
  width: 78%;
  top: 300px;
}

.placement-message {
  min-height: 40px;
  background: #20a91f;
  border-radius: 7px 0 0 7px;
  align-items: center;
  color: white;
  padding-left: 17px;
  margin-bottom: 3px;
  z-index: 1 !important;
}

.camera-card-title {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.controller-action-card {
  position: relative;

  .controller-card-title {
    min-height: 50px;
  }

  .animate-show-hide {
    text-align: center;
  }

  .action-product-list-header {
    border-bottom: 1px solid darkgray;
    margin-bottom: 5px;
  }

  .detail-quantity-separate {
    border-right: 1px solid darkgray;
  }

  .d-flex {
    display: flex;
  }

  .product-detail {
    max-height: 215px;
    overflow-y: scroll;

    .product-detail-row {
      border-bottom: 1px solid darkgray;
    }

    .bom-product-div {
      background: #adadad4a;
      padding: 10px;
      margin: 0 -10px 10px -10px;
    }

    .product-complete-packing {
      background-color: #1dda1559;
    }
  }

  .band {
    width: 100%;
    height: 17rem;
    position: absolute;
    background: #77777754;
    opacity: 0.7;
  }

  .in-package-process-done {
    color: #11b00a;
    z-index: 1000;
    position: absolute;
    right: 5px;
    top: 5px;
    background: white;
    border-radius: 25px;
    font-weight: 900;
  }

  .band-text {
    background: gray;
    width: 100%;
    text-align: center;
    color: white;
  }
}

.item-delete-button {
  background: red;
  height: 100%;
  align-items: center;
  display: flex;
  text-align: center;
  color: white;
}

.item-manuel-prepare-button {
  background: #11b00a;
  height: 100%;
  align-items: center;
  display: flex;
  text-align: center;
  color: white;
}

.item-quantity-text {
  text-align: center;
}

.placement-message-modal {

  .modal-dialog {
    width: 100%;
    margin: 0;
  }

  .modal-content {
    box-shadow: none;
  }

  .placement-message-modal-type-div {
    text-align: center;

    md-icon {
      font-size: 40pt;
      text-align: center;
      vertical-align: baseline;
      width: 100% !important;
    }
  }

  md-list-item {
    padding: 0;
    font-weight: 400;
    font-size: 2rem;
    align-items: center;
    margin-bottom: 10px;

    md-icon {
      margin: 0 10px 0 0 !important;
    }
  }

}

.placement-message-modal#placement-message-error {
  .modal-body {
    background: #f75656c2;
  }

  .placement-message-modal-type-div {
    md-icon {
      color: #a91e1ec4;
    }
  }

  md-list-item {
    md-icon {
      color: #a91e1ec4;
    }
  }

  .close-modal-button {
    justify-content: center;
    margin: 0;
  }
}

.placement-message-modal#placement-message-success {

  .modal-body {
    background: #47a34785;
  }

  .placement-message-modal-type-div {
    md-icon {
      color: #537453;
    }
  }

  md-list-item {
    md-icon {
      color: #537453;
    }
  }

  .placement-modal-action-div {
    //margin-top: 20px;
    margin-bottom: 20px;
  }

  .scanned-product-quantity {
    font-size: 20px;
    margin-top: 9px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .scanned-product-bulk-placement-button {
    margin-top: 5px;
    margin-bottom: 5px;
    background: #146250;
    color: white;
    height: 40px;
  }

  .scanner-resume-button {
    background: #858d8b;
    color: white;
    height: 40px;
  }

  .close-modal-button-div {
    margin-top: 5px;

    button {
      margin-top: 0;
      margin-bottom: 0;
      background: #c1c1c1;
    }
  }

  .min-product-requirement-quantity {
    background: #e8d3ae;
  }

  .max-product-requirement-quantity {
    background: #772121;
    color: white;
  }

  .scanned-product-bulk-placement-list {
    margin-top: 5px;
    margin-left: 9px;
    font-weight: 600;
  }
}


.camera-card-title {
  .camera-card-stop-button {
    position: absolute;
    right: 0;
  }
}

.barcode-reader-div {
  /*max-width: 300px;
  min-width: 300px;
  position: relative*/
}

.prepare-action-list-td-small {
  float: left;
  display: block;
  width: 100%;
}

#transporter-control-bulk-list {
  md-card-content {
    .transporter-control-bulk-list-content {
      max-height: 500px;
      overflow: hidden;
      overflow-y: scroll;

      .transporter-control-bulk-list-rows {
        .transporter-control-bulk-list-row {
          button {
            background: #80f280;

            md-icon {
              color: white;
            }
          }
        }
      }
    }

    .lists {
      padding-block: 5px;
      border-top: 1px solid #eeeeee;

      &:last-child {
        border-bottom: 1px solid #eeeeee;
      }
    }


    .transporter-control-bulk-list-header {
      text-align: center;
      width: calc(100% - 7px);
    }
  }

  .transporter-control-bulk-list-rows:nth-child(odd) {
    background-color: #e1e1e18a;
  }

  .transporter-control-bulk-list-rows:nth-child(even) {
    background-color: #f4f4f4cc;
  }

}

.shelf-list-rows:nth-child(odd) {
  background-color: #e1e1e18a;
}

.shelf-list-rows:nth-child(even) {
  background-color: #f4f4f4cc;
}

.gray-navigate {
  color: #ccc;
}

.action-prepare-list {

  .action-prepare-list-header {
    border-bottom: 1px solid #b3b3b3;
    align-items: center;
    padding: 5px;
    text-align: left;
    font-weight: 700;
  }

  .action-prepare-list-line {
    border-radius: 2px;
    border: 1px grey;
    align-items: center;
    padding: 5px;

    .action-prepare-list-line-action {
      align-items: center;
      justify-content: center;
      display: flex;

      button {
        width: 90%;
      }
    }
  }
}

.prepare-list-message-box {
  display: flex;
  justify-content: center;
}


.prepare-list-product-list-complete {
  background-color: #e5fbcf !important;
}

.prepare-list-product-list-selected {
  border:3px dashed #ccc;

}

.prepare-list-product-list-continue {
  background-color: #ffeb3b3b !important;
}
.prepare-list-product-list-missing {
  background-color: #ff583B3a !important;
}

.prepare-list-product-list {
  min-height: 50px;
  display: flex;
  padding: 5px;
  align-content: center;
  border-bottom: 1px solid #eee;
  & {
    display: flex;
    align-items: center;
  }

  &:nth-child(odd) {
    background-color: #f5faff;
  }

  &:nth-child(even) {
    background-color: #fff;
  }
}

.prepare-package-continue {
  background-color: #ffeb3b3b !important;
}

.prepare-package-complete {
  background-color: #e5fbcf !important;
}

.prepare-package-selected {
  font-weight: 700;
}

.continue-bg {
  background-color: #FFF59D !important;
}

.success-bg {
  background-color: #2dd881 !important;
}

.md-alert-info-div {
  background: #c5e7e8;
  color: #171717;
  border-radius: 5px;
  margin: 3px;
}

.missing-quantity-info {
  text-align: center;
  border: 1px solid gray;
  border-radius: 8px;

  & {
    div {
      line-height: 3;
    }

    span:first-child {
      font-weight: 700;
      border-bottom: 1px solid gray;
    }
  }
}

.selected-shelf-header {
  position: absolute;
  font-size: 10px;
  top: -5px;
  left: 6px;
  background-color: #f7f7f7;
  padding: 0 5px;
  border-radius: 5px;
}

.shipment-list {
  &.selected-shipment-list {
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .shipment-list-provider {
    margin-bottom: 15px;
  }

  .shipment-list-actions {
    .shipment-list-action-header {
      margin-bottom: 5px;

      p {
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
}


.action-lot-relation {
  text-align: center;
  border: 1px solid gray;
  border-radius: 3px;

  .action-lot-relation-row:nth-child(odd) {
    background-color: #e1e1e18a;
  }

  .action-lot-relation-row:nth-child(even) {
    background-color: #f4f4f4cc;
  }

  .action-lot-relation-row-done {
    background-color: #2dd881 !important;

  }
}

.shipping-list-card{
  border-radius: 10px !important;
  text-align: center;

  .shipping-list-card-wait{
    text-align: center;
    font-size: 50px;
    color: #847d7d;
    font-weight: 700;
  }
}
.shipping-action-list-row:nth-child(odd) {
     background-color: #e1e1e18a;
   }

.shipping-action-list-row:nth-child(even) {
  background-color: #f4f4f4cc;
}
.shipping-action-list-row-counted{
  background-color: #2dd881 !important;

}
