.shipmentError{
  background-color: #e19090 !important;
}
.process-current{
  background: #5db85b !important;
  color: #000;
}

.process-complete{
  color: #aaa;
}
.process-wait{
  color: #000;
}

.background-div {
  width: 100%; /* Div'in genişliği */
  height: 200px; /* Div'in yüksekliği, istediğiniz değere göre ayarlayabilirsiniz */
  //background: linear-gradient(to right, #ff0000 0%, #ff0000 25%, #ffffff 25%, #ffffff 100%);
  /* Sol 1/4'üne kırmızı, geri kalanına beyaz bir arka plan vermek için bir lineer gradient */
}

.list-status-normal{
  background: linear-gradient(to right, #f5faff 0%, #f5faff 10%, #ffffff 25%, #ffffff 100%)
}
.list-status-alert{
  background: linear-gradient(to right, #dce0a0 0%, #dce0a0 10%, #ffffff 25%, #ffffff 100%)
}
.list-status-complete{
  background: linear-gradient(to right, #cae4c361 0%, #cae4c361 10%, #ffffff 25%, #ffffff 100%)

}

.scroll-fab {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 1000; /* Butonun diğer öğelerin üstünde görünmesini sağlar */
}
